<template>
  <div>
    <template v-if="showButton">
      <button :disabled="!isEditable" class="status-button" @click="toggleArchiveStatus">
        <font-awesome-icon 
          :icon="['fas', currentValue ? 'archive' : 'archive']" 
          class="button-icon"
        />
        {{ currentValue ? 'Unarchive' : 'Archive' }}
      </button>
    </template>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: ['params'],
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      currentValue: false,
      isEditable: false,
      showButton: false
    };
  },
  beforeMount() {
    if (this.params.data && (this.params.data.dataSource === 'API' || this.params.data.status !== '')) {
      this.showButton = true;
      this.isEditable = this.params.data?.confirmed;
      this.currentValue = this.params.data.status === 'removed';
    } else {
      this.showButton = false;
    }
  },
  methods: {
    toggleArchiveStatus() {
      this.currentValue = !this.currentValue;
      if (this.currentValue) {
        this.params.node.setDataValue('status', 'removed');
      } else {
        this.params.node.setDataValue('status', '');
      }
      this.params.api.redrawRows({ rowNodes: [this.params.node] });
      this.params.api.onFilterChanged();
    }
  }
};
</script>


<style>
.status-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: #087AD1;
  padding: 1px 8px;
  color: white;
}

.button-icon {
  font-size: 0.9em;
}

.status-button:hover {
  background-color: #066bb3;
}

.status-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
