export default function dateSortComparator(cellValueA, cellValueB) {
	// If both A and B are null/empty => treat them as equal
	if (!cellValueA && !cellValueB) {
		return 0;
	}

	// If only A is null => place after B
	if (!cellValueA) {
		return 1;
	}

	// If only B is null => place after A
	if (!cellValueB) {
		return -1;
	}

	// Otherwise, compare by date
	const [yearA, monthA, dayA] = cellValueA.split('-').map(Number);
	const [yearB, monthB, dayB] = cellValueB.split('-').map(Number);

	const dateA = new Date(Date.UTC(yearA, monthA - 1, dayA));
	const dateB = new Date(Date.UTC(yearB, monthB - 1, dayB));

	// Zero out the time so we only compare by date
	dateA.setUTCHours(0, 0, 0, 0);
	dateB.setUTCHours(0, 0, 0, 0);

	// Subtract to get a numeric result:
	// If negative => A < B; if positive => A > B; if zero => A == B
	return dateA - dateB;
}
