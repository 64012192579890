<template>
  <div class="modal-overlay" v-if="show" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="header-left">
          <h3>Activity History</h3>
          <button class="refresh-button" @click="refreshActivities">
            Refresh
          </button>
        </div>
        <button class="close-button" @click="closeModal">&times;</button>
      </div>
      <div class="modal-body">
        <ag-grid-vue
          class="ag-theme-alpine activity-grid"
          :columnDefs="columnDefs"
          :rowData="activities"
          :defaultColDef="defaultColDef"
          :pagination="true"
          :paginationPageSize="10"
          :theme="myTheme"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { themeQuartz } from 'ag-grid-community';

const quartzExcel = {
  // Use the Excel brand green, or pick your own
  accentColor: "#217346",

  // Keep background white
  backgroundColor: "#FFFFFF",

  // Subtle gray border color for grid lines
  borderColor: "#D0D7D0",

  // Keep corners slightly rounded (feel free to set to 0 for sharp corners)
  borderRadius: 2,

  // Force light mode
  browserColorScheme: "light",

  // Slight horizontal padding
  cellHorizontalPaddingScale: 0.5,

  // Inherit chrome background from the base background
  chromeBackgroundColor: {ref: "backgroundColor"},

  // Turn on both row and column borders to mimic Excel grid lines
  rowBorder: true,
  columnBorder: true,

  // Use a standard, easy-to-read font. Inter is fine, or "Arial" as an alternative
  fontFamily: {googleFont: "Inter"},

  // Slightly smaller text (Excel-like)
  fontSize: 12,
  foregroundColor: "#000000",

  // Subtle gray header background with bold black text
  headerBackgroundColor: "#D9D9D9",
  headerFontSize: 12,
  headerFontWeight: 700,
  headerTextColor: "#000000",

  // Slightly smaller header vertical padding
  headerVerticalPaddingScale: 0.5,

  // Banded rows: light gray for odd rows
  oddRowBackgroundColor: "#F3F3F3",

  // Decrease row vertical padding for a tighter table
  rowVerticalPaddingScale: 0.6,

  // Side panel border on
  sidePanelBorder: true,

  // Minimal spacing
  spacing: "2px",

  // Optional outer wrapper styling
  wrapperBorder: true,
  wrapperBorderRadius: 2
};

export default {
  name: 'ActivityModal',
  components: {
    AgGridVue
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    activities: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myTheme: themeQuartz
          .withParams(quartzExcel),
      columnDefs: [
        {
          field: 'timestamp',
          headerName: 'Time',
          sort: 'desc',
          sortable: true,
          filter: true,
          valueFormatter: params => {
            if (!params.value) return '';
            return new Date(params.value).toLocaleString();
          }
        },
        {
          field: 'loadNo',
          headerName: 'Load #',
          sortable: true,
          filter: true
        },
        {
          field: 'column',
          headerName: 'Field Changed',
          sortable: true,
          filter: true
        },
        {
          field: 'value',
          headerName: 'Value',
          sortable: true,
          filter: true,
          valueFormatter: params => {
            if (params.data.column === 'status') {
              if (params.value === 'removed') return 'archived';
              if (params.value === '') return 'unarchived';
            }
            return params.value;
          }
        },
        {
          field: 'source',
          headerName: 'Source',
          sortable: true,
          filter: true
        }
      ],
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true
      }
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    refreshActivities() {
      this.$emit('refresh');
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e2e2e2;
}

.modal-header h3 {
  margin: 0;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.modal-body {
  padding: 1rem;
  overflow: auto;
  flex-grow: 1;
}

.activity-grid {
  height: 500px;
  width: 100%;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.refresh-button {
  background-color: #087AD1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.refresh-button:hover {
  background-color: #066bb3;
}
</style> 