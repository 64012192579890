<template>
  <div class="activity-panel">
    <div class="panel-header">
      <h4 v-if="loadNo">Activity History - Load #{{ loadNo }}</h4>
      <h4 v-else>Activity History</h4>
      
      <div v-if="loadNo">
        <button class="refresh-button" @click="refreshActivities" title="Refresh activity data">
          ↻
        </button>
      </div>
    </div>
    
    <div class="panel-content">
      <div v-if="!loadNo" class="no-load-selected">
        Select a load to view its activity history
      </div>
      <div v-else-if="loading" class="loading">
        Loading activities...
      </div>
      <div v-else-if="activities.length === 0" class="no-activities">
        No activity history found for this load.
      </div>
      <div v-else class="activity-list">
        <div v-for="activity in sortedActivities" :key="activity.timestamp" class="activity-item">
          <div class="activity-time tooltip">
            {{ formatRelativeTime(activity.timestamp) }}
            <span class="tooltip-text">{{ formatDate(activity.timestamp) }}</span>
          </div>
          <div class="activity-details">
            <strong>{{ formatColumnName(activity.column) }}</strong> was changed to
            <span :class="getValueClass(activity)" class="activity-value">{{ formatValue(activity) }}</span>
          </div>
          <div class="activity-source">
            Source: {{ activity.source }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityHistoryPanel',
  data() {
    return {
    };
  },
  // AgGrid will inject these props automatically
  props: ['params'],
  computed: {
    loadNo() {
      return this.params?.context?.componentParent?.selectedLoadNo;
    },
    activities() {
      return this.params?.context?.componentParent?.historyActivities || [];
    },
    loading() {
      return this.params?.context?.componentParent?.activityLoading || false;
    },
    sortedActivities() {
      return [...this.activities].sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
    }
  },
  methods: {
    refreshActivities() {
      if (this.loadNo && this.params?.context?.componentParent) {
        // Instead of directly mutating the parent component, 
        // emit an event to the parent to trigger activity refresh
        this.params.context.componentParent.refreshActivities(this.loadNo);
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      return date.toLocaleString();
    },
    formatRelativeTime(timestamp) {
      if (!timestamp) return '';
      
      const now = new Date();
      const date = new Date(timestamp);
      const diffInSeconds = Math.floor((now - date) / 1000);
      
      if (diffInSeconds < 60) {
        return 'just now';
      } else if (diffInSeconds < 3600) {
        const mins = Math.floor(diffInSeconds / 60);
        return `${mins} ${mins === 1 ? 'min' : 'mins'} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (diffInSeconds < 2592000) {
        const days = Math.floor(diffInSeconds / 86400);
        return days === 1 ? 'yesterday' : `${days} days ago`;
      } else if (diffInSeconds < 31536000) {
        const months = Math.floor(diffInSeconds / 2592000);
        return `${months} ${months === 1 ? 'month' : 'months'} ago`;
      } else {
        const years = Math.floor(diffInSeconds / 31536000);
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
      }
    },
    formatValue(activity) {
      // Format status values
      if (activity.column === 'status') {
        if (activity.value === 'removed') return 'archived';
        if (activity.value === '') return 'unarchived';
      }
      return activity.value;
    },
    getValueClass(activity) {
      const classes = ['activity-value'];
      // Add specific classes for status changes
      if (activity.column === 'status') {
        if (activity.value === 'removed') {
          classes.push('status-archived');
        } else if (activity.value === '') {
          classes.push('status-unarchived');
        }
      } else if (activity.column === 'confirmed' || 
                activity.column === 'staged' || 
                activity.column === 'qc' || 
                activity.column === 'paperwork') {
        // Boolean values
        if (activity.value === true || activity.value === 'True' || activity.value === '1' || activity.value === 1) {
          classes.push('boolean-true');
        } else {
          classes.push('boolean-false');
        }
      }
      return classes;
    },
    formatColumnName(columnName) {
      if (!columnName) return '';
      
      // Handle special cases
      const specialCases = {
        'qc': 'QC',
        'dlxLoadNo': 'Karavan #',
        'loadNo': 'DLX #',
        'timeIn': 'Time In',
        'timeOut': 'Time Out',
        'timeLoaded': 'Time Loaded',
        'timeSlot': 'Time Slot',
        'finalStopName': 'Final Stop Name',
        'finalStopCS': 'Final Stop CS',
        'totalWeight': 'Total Weight',
        'totalMiles': 'Total Miles',
        'custInfo': 'Customer Info',
        'loadRevenue': 'Load Revenue',
        'loadCost': 'Load Cost',
        'origShipDate': 'Original Ship Date',
        'shippingCost': 'Shipping Cost',
        'dueDate': 'Due Date',
        'readyDate': 'Ready Date',
        'tenderDate': 'Tender Date',
        'shipDate': 'Ship Date',
        'dataSource': 'Data Source',
        'new_row': 'New Load Created'
      };
      
      if (specialCases[columnName]) {
        return specialCases[columnName];
      }
      
      // Convert camelCase to Title Case with spaces
      return columnName
        // Insert a space before all uppercase letters
        .replace(/([A-Z])/g, ' $1')
        // Replace first char with uppercase
        .replace(/^./, str => str.toUpperCase());
    }
  }
}
</script>

<style>
.activity-panel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;
}

.panel-header {
  padding: 0.4rem 0.6rem;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-header h4 {
  margin: 0;
  font-size: 0.9rem;
}

.panel-actions {
  display: flex;
  gap: 8px;
}

.refresh-button {
  background: none;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0.2rem;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-button:hover {
  color: #087AD1;
}

.refresh-icon {
  font-size: 16px;
}

.load-info,
.load-details,
.load-detail {
  display: none;
}

.panel-content {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.activity-item {
  padding: 0.4rem 0.5rem;
  border: 1px solid #eee;
  border-radius: 3px;
  background: #f9f9f9;
  font-size: 0.85rem;
  word-break: break-word;
  line-height: 1.2;
}

.activity-time {
  color: #666;
  font-size: 0.75rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #ccc;
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 0.75rem;
  white-space: nowrap;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: 125%;
  left: 0;
  
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you hover over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.activity-details {
  margin-bottom: 0.2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.activity-value {
  background: #e8f4ff;
  padding: 0.1rem 0.2rem;
  border-radius: 2px;
  font-family: monospace;
  font-size: 0.8rem;
  max-width: 100%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.status-archived {
  background-color: #ffebe9;
  color: #cf222e;
}

.status-unarchived {
  background-color: #dafbe1;
  color: #1a7f37;
}

.boolean-true {
  background-color: #dafbe1;
  color: #1a7f37;
}

.boolean-false {
  background-color: #ffebe9;
  color: #cf222e;
}

.activity-source {
  font-size: 0.7rem;
  color: #666;
  margin-top: 0.1rem;
}

.loading, .no-activities, .no-load-selected {
  padding: 0.75rem;
  text-align: center;
  color: #666;
  font-size: 0.85rem;
}

/* Fix for ag-Grid styles that might override ours */
.ag-theme-quartz .ag-tool-panel-wrapper .activity-panel,
.ag-theme-quartz .ag-tool-panel-wrapper .activity-panel * {
  box-sizing: border-box;
  font-family: inherit;
}

.ag-theme-quartz .ag-tool-panel-wrapper .activity-panel .activity-item {
  background: #f9f9f9;
  padding: 0.4rem 0.5rem;
}

.ag-theme-quartz .ag-tool-panel-wrapper .activity-panel .panel-header {
  background: #f5f5f5;
}

.ag-theme-quartz .ag-tool-panel-wrapper .tooltip .tooltip-text {
  font-weight: normal;
  padding: 5px 8px;
}
</style> 
