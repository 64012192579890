<template>
  <button class="history-button" @click="showHistory">
    History
  </button>
</template>

<script>
export default {
  name: 'HistoryButtonRenderer',
  methods: {
    showHistory() {
      this.params.context.componentParent.showActivityHistory(this.params.data.loadNo);
    }
  }
}
</script>

<style>
.history-button {
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: #087AD1;
  padding: 1px 4px 1px 4px;
  color: white;
}

.history-button:hover {
  background-color: #066bb3;
}

.icon {
  font-size: 1rem;
}
</style> 